import React from "react";
import TopBar from "../Common/Topbar";
import About from "../Common/About";
import Footer from "../Common/Footer";
import Header from "../Common/Header";

export default function AboutUs() {
    return (
        <div>
            <TopBar />
            {/* Navbar_Start */}
            <div className="container-fluid position-relative p-0">
                <Header />
                <div
                    className="container-fluid bg-primary py-5 bg-header-about"
                    style={{ marginBottom: "90px" }}
                >
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">About Us</h1>
                            <h6
                                className="display-4 text-white animated zoomIn"
                                style={{
                                    fontSize: "25px",
                                }}
                            >
                                Crafting amazing digital solutions is not our work, it’s our
                                duty.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            {/* Navbar_End */}

            <About />

            {/* <TeamMember /> */}

            <Footer />
        </div>
    );
}
