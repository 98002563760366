import React from "react";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    return (
        <div>
            {/* Navbar_Start */}
            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                    <div className="flex-item">
                        <div className="logo">
                            <a href="/">
                                <img
                                    src="assets/img/itinformatix.png"
                                    alt=""
                                    className="img-fluid"
                                />
                            </a>
                        </div>
                    </div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                    >
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            <Link className="nav-link" to="/">
                                Home
                            </Link>
                            <Link className="nav-link" to="/aboutUs">
                                About Us
                            </Link>
                            <Link className="nav-link" to="/portfolio">
                                Portfolio
                            </Link>
                            <Link className="nav-link" to="/services">
                                Services
                            </Link>
                            <Link className="nav-link" to="/IndustrialTraining">
                                Industrial Training
                            </Link>
                            <Link className="nav-link" to="/contactUs">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </nav>
            </div>

            {/* Navbar_End */}
            <div className="container py-5">
                <div className="row g-5" style={{ paddingTop: "70px" }}>
                    <div className="col-lg-12">
                        <div className="pb-3 mb-5">
                            <div>
                                <h5
                                    className="fw-bold text-uppercase mb-3"
                                    style={{ color: "#2ed1d5", fontSize: "35px" }}
                                >
                                    Privacy Policy
                                </h5>
                                <p className="mb-5" style={{ fontStyle: "oblique" }}>
                                    At ITINFORMATIX, we are committed to
                                    protecting your privacy and ensuring the security of your
                                    personal information. This Privacy Policy outlines how we
                                    collect, use, disclose, and safeguard your data when you use
                                    our digital marketing services and digital marketing training
                                    programs. By accessing or using our services, you consent to
                                    the practices described in this policy.
                                </p>
                            </div>
                            <div>
                                <h5
                                    className="fw-bold text-uppercase mb-3"
                                    style={{ color: "#2ed1d5" }}
                                >
                                    Information We Collect
                                </h5>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    We may collect various types of information from you,
                                    including:
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Personal Information</b>: This includes
                                    your name, email address, contact information, and any other
                                    details you provide when signing up for our services or
                                    training programs.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Payment Information</b>: If you make a
                                    purchase through our website, we collect payment information
                                    such as credit card details. However, we do not store your
                                    complete payment information; it is securely processed by our
                                    third-party payment processors.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Usage Data</b>: We may automatically
                                    collect certain information about your interactions with our
                                    website, such as your IP address, browser type, operating
                                    system, and pages visited. This data helps us improve our
                                    services and tailor our content to your needs.
                                </p>
                            </div>
                            <div>
                                <h1
                                    className="fw-bold text-uppercase mb-3 pt-5"
                                    style={{ color: "#2ed1d5", fontSize: "20px" }}
                                >
                                    How We Use Your Information
                                </h1>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    We use the collected information for various purposes,
                                    including:
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Providing Services</b>: We use your
                                    personal information to deliver the digital marketing services
                                    and training programs you've requested, including
                                    communication and updates related to those services.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Communication</b>: We may use your email
                                    address to send you information about our services,
                                    promotional offers, newsletters, and updates. You can opt out
                                    of these communications at any time.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Improvement</b>: We analyze usage data to
                                    improve our website, services, and training programs. This
                                    helps us better understand your preferences and optimize your
                                    experience.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Security</b>: We employ industry-standard
                                    security measures to protect your information from
                                    unauthorized access, alteration, disclosure, or destruction.
                                </p>
                            </div>
                            <div>
                                <h1
                                    className="fw-bold text-uppercase mb-3 pt-5"
                                    style={{ color: "#2ed1d5", fontSize: "20px" }}
                                >
                                    Disclosure of Your Information
                                </h1>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    We don't share your information to anywhere, however we may
                                    share your information in the following circumstances:
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Third-Party Service Providers</b>: We may
                                    share your information with trusted third-party service
                                    providers who assist us in delivering our services, processing
                                    payments, and analyzing data. These providers are bound by
                                    confidentiality agreements and are only allowed to use your
                                    information for specific purposes.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Legal Requirements</b>: We may disclose
                                    your information if required by law, regulation, or legal
                                    process, or to protect our rights, privacy, safety, or
                                    property, as well as that of our customers or the public.
                                </p>
                            </div>
                            <div>
                                <h1
                                    className="fw-bold text-uppercase mb-3 pt-5"
                                    style={{ color: "#2ed1d5", fontSize: "20px" }}
                                >
                                    Your Choices
                                </h1>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    You have the following rights regarding your personal
                                    information:
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Access and Correction</b>: You can
                                    request access to the personal information we hold about you
                                    and update or correct any inaccuracies.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Data Portability</b>: You can request a
                                    copy of your personal information in a structured, commonly
                                    used, and machine-readable format.
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    <b className="color">Opt-Out</b>: You can opt out of receiving
                                    promotional emails from us by following the instructions
                                    provided in those emails.
                                </p>
                            </div>
                            <div>
                                <h1
                                    className="fw-bold text-uppercase mb-1 pt-5"
                                    style={{ color: "#2ed1d5", fontSize: "20px" }}
                                >
                                    Cookies and Tracking Technologies
                                </h1>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    We may update this Privacy Policy from time to time to reflect
                                    changes in our practices or for other operational, legal, or
                                    regulatory reasons. The updated policy will be posted on our
                                    website, and the date of the latest revision will be
                                    indicated.
                                </p>
                            </div>
                            <div>
                                <h1
                                    className="fw-bold text-uppercase mb-1 pt-5"
                                    style={{ color: "#2ed1d5", fontSize: "20px" }}
                                >
                                    Links to Third-Party Sites
                                </h1>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    Our website may contain links to third-party websites. We are
                                    not responsible for the privacy practices or content of these
                                    sites. We encourage you to review the privacy policies of any
                                    third-party sites you visit.
                                </p>
                            </div>
                            <div>
                                <h1
                                    className="fw-bold text-uppercase mb-1 pt-5"
                                    style={{ color: "#2ed1d5", fontSize: "20px" }}
                                >
                                    Changes to this Privacy Policy
                                </h1>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    We may update this Privacy Policy from time to time to reflect
                                    changes in our practices or for other operational, legal, or
                                    regulatory reasons. The updated policy will be posted on our
                                    website, and the date of the latest revision will be
                                    indicated.
                                </p>
                            </div>
                            <div>
                                <h1
                                    className="fw-bold text-uppercase mb-1 pt-5"
                                    style={{ color: "#2ed1d5", fontSize: "20px" }}
                                >
                                    Contact Us
                                </h1>
                                <p className="mb-2" style={{ fontStyle: "oblique" }}>
                                    If you have any questions or concerns about this Privacy
                                    Policy or how we handle your information, please contact us at{" "}
                                    <a href="mailto:info@itinformatix.com">
                                        info@itinformatix.com
                                    </a>
                                </p>
                                <p className="mb-4" style={{ fontStyle: "oblique" }}>
                                    By using our digital marketing services and digital marketing
                                    training programs, you acknowledge that you have read and
                                    understood this Privacy Policy and agree to its terms and
                                    conditions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
