import React from "react";
import TopBar from "../Common/Topbar";
import Service from "../Common/Service";
import Testimonial from "../Common/Testimonial";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";
import Header from "../Common/Header";

export default function Services() {
    return (
        <div>
            <TopBar />
            {/* Navbar_Start */}
            <div className="container-fluid position-relative p-0">
                <Header />
                <div
                    className="container-fluid bg-primary py-5 bg-header-service"
                    style={{ marginBottom: "90px" }}
                >
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">Services</h1>
                            <h6
                                className="display-4 text-white animated zoomIn"
                                style={{ fontSize: "25px" }}
                            >
                                Building a relationship between IT Services.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            {/* Navbar_End */}

            <Service />
            <Testimonial />
            <Footer />
        </div>
    );
}
