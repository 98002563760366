import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div>
            <div
                className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
                data-wow-delay="0.1s"
            >
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <div
                                className="d-flex flex-column align-items-center justify-content-center text-center h-100 p-4"
                                style={{ backgroundColor: "white" }}
                            >
                                <div className="flex-item">
                                    <div className="logo">
                                        <a href="/">
                                            <img
                                                src="assets/img/itinformatix.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <h6 className="mt-3">
                                    Itinformatix is a distinguished website development and web
                                    designing company serving to global clients. We provide highly
                                    professional services of the latest technology for web
                                    services to make your business more efficient and unique. We
                                    believe client service is not just a department but it’s a
                                    responsible job hence we maintain all possible high quality
                                    standard.
                                    {/* Since 2019, we've been a prominent IT firm dedicated to
                                    providing you with high-quality web design and development,
                                    digital marketing services. */}
                                </h6>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="row gx-5">
                                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Get In Touch</h3>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-geo-alt text-primary me-2"></i>
                                        <p className="mb-0">
                                            401 - Govardhan shikhar, 1177/2 Kanadia main road, Axis
                                            bank building Indore, 452016.
                                        </p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"></i>
                                        <a
                                            href="mailto:salesitinformatix@gmail.com"
                                            className="action-email"
                                            style={{ color: "white" }}
                                        >
                                            salesitinformatix@gmail.com
                                        </a>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <p className="mb-0">
                                            {" "}
                                            <a href="tel:+91 9770216812" style={{ color: "white" }}>
                                                {" "}
                                                +91 9770216812{" "}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <a
                                            className="btn btn-primary btn-square me-2"
                                            target="_blank"
                                            href="https://www.facebook.com/itinformatix"
                                        >
                                            <i className="fab fa-facebook-f fw-normal"></i>
                                        </a>
                                        <a
                                            className="btn btn-primary btn-square me-2"
                                            target="_blank"
                                            href="https://www.linkedin.com/company/itinformatix/"
                                        >
                                            <i className="fab fa-linkedin-in fw-normal"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Quick Links</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <Link className="text-light mb-2" to="/">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>{" "}
                                            Home
                                        </Link>
                                        <Link className="text-light mb-2" to="/aboutUs">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>{" "}
                                            About Us
                                        </Link>
                                        <Link className="text-light mb-2" to="/portfolio">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>{" "}
                                            Portfolio
                                        </Link>
                                        <Link className="text-light mb-2" to="/services">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>{" "}
                                            Services
                                        </Link>
                                        <Link className="text-light mb-2" to="/IndustrialTraining">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>{" "}
                                            Industrial Training
                                        </Link>
                                        <Link className="text-light mb-2" to="/contactUs">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>{" "}
                                            Contact Us
                                        </Link>
                                        <Link className="text-light mb-2" to="/privacy-policy">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>{" "}
                                            Privacy Policy
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h4 className="text-light mb-0">Industry Verticals</h4>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <a className="text-light mb-2">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>On
                                            Demand Services
                                        </a>
                                        <a className="text-light mb-2">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>
                                            Restaurant and Delivery
                                        </a>
                                        <a className="text-light mb-2">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>
                                            Healthcare
                                        </a>
                                        <a className="text-light mb-2">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>
                                            Logistics
                                        </a>
                                        <a className="text-light mb-2">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>
                                            Education
                                        </a>
                                        <a className="text-light mb-2">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>
                                            Retails
                                        </a>
                                        <a className="text-light mb-2">
                                            <i className="bi bi-arrow-right text-primary me-2"></i>
                                            Wellness and Fitness
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="container-fluid text-white"
                style={{ background: "#061429" }}
            >
                <div className="container text-center">
                    <div className="row justify-content-end">
                        <div className="col-lg-12 col-md-6">
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ height: "75px" }}
                            >
                                <p className="mb-0">
                                    <a className="text-white border-bottom" href="#">
                                        Copyright 2023-24 © Itinformatix All rights reserved.
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a
                href="#"
                className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
            >
                <i className="bi bi-arrow-up"></i>
            </a>
        </div>
    );
}
