import React from "react";
import TopBar from "../Common/Topbar";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";
import Seemore from "../../Component/SeeMore";
import Header from "../Common/Header";

export default function Portfolio() {
    const FormDuniya =
        "  India's No 1 form filling platform for aspirant of government exam.Student Exam forms can be submitted on simple click using easy user interface.";
    const OnDemandApp =
        " It’s one of the largest home service app. Through the app, you can order reliable home services - everything be it electrician, plumber, cleaner, trainer or an interior designer etc.";
    const FoodOnTime =
        " Food on time lets you discover restaurants to eat out at or order in from. Browse through restaurant menus, photos, user reviews and ratings to decide where you want to eat, and use the map feature to guide you there.";
    const YummyFood =
        "Intra city food delivery app having restaurants across the city to deliver delicious food at your door steps. Provide exiting offers and discounts.";
    const Teleport =
        "Luggage Teleport Inc. acknowledges the worth of time and aims to ease the lives of travelers by providing an on-demand luggage transportation service between airports and hotels, and among hotels in the same metropolitan area.";
    const Luggport =
        "Take the most out of your business and leisure travels with the confidence that your luggage is well taken care of.";
    const AtyCloud =
        "A website and customer portal designed for ATY cloud to promote & manage their cloud solutions, managed services and consulting business.";
    const LeadManagement =
        "Its a property (lead) management mobile app helps you in managing your Agents, Leads, Prospect/customer database in an effective manner so that you can track all the details on your finger tips.";
    const GradeChoice =
        "GradeChoice is a leading service provider for recruitment and training needs providing exceptional services to our clients and job seekers across the country.";
    const AvolaAdmin =
        "An online video upload and streaming platform which is used by renowned streaming provider in the market. Admin can upload the video and User can enjoy at other end.";
    const DemandServiceApp =
        "It’s one of the largest home service app. Through the app, you can order reliable home services - everything be it electrician, plumber, cleaner, trainer or an interior designer etc.";
    const HelperOnTime =
        "With Helper on time app you can book a service from qualified and background verified service provider. Select a nearby service provider for your home, Business and Car needs anytime and anytime. Service providers can enroll themselves in the market place as well.";
    const PriceScanner =
        "An ecommerce platform which has integration with Amazone and Ebay. User can get best deals and discounts.";
    const Archvtech =
        "An exclusive showcase of 10000 properties & infrastructures. An intuitive web portal designed in Code Ignite";
    const Agriculture =
        "Helps Government organization and banks to collect and manage data related to agricultural activity across the nation and exact reporting.";
    const Justoverse =
        "JUSTO aims to build an innovative knowledge and strategy-driven real estate services organisation that facilitates developers across all spectrum of the ‘Design to Delivery’ product cycle.";
    const abbypages =
        "All Black Business Yellow Pages. Support Black Excellence! Discover Events, Jobs, Goods, Services, Directions, Reviews, Deals, and More on ABBYPAGES.";
    return (
        <div>
            <TopBar />
            {/* Navbar_Start */}
            <div className="container-fluid position-relative p-0">
                <Header />
                <div
                    className="container-fluid bg-primary py-5 bg-header-portfolio"
                    style={{
                        marginBottom: "90px",
                    }}
                >
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">
                                Portfolio
                            </h1>
                            <h6
                                className="display-4 text-white animated zoomIn"
                                style={{ fontSize: "25px" }}
                            >
                                Building a relationship between IT Services.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            {/* Navbar_End */}

            {/* Portfolio Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div
                        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
                        style={{ maxWidth: "900px" }}
                    >
                        <h5 className="fw-bold text-uppercase" style={{ color: "#2ed1d5" }}>
                            Portfolio
                        </h5>
                        <h1 className="mb-0">
                            Explore Our Portfolio for Website and Mobile App Development Work
                        </h1>
                    </div>
                    <div className="row g-5">
                        <Seemore
                            id={1}
                            img="assets/img/home-project4.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Form Duniya`}
                            data={FormDuniya}
                        />
                        <Seemore
                            id={2}
                            img="assets/img/home-project2.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`On Demand App`}
                            data={OnDemandApp}
                        />
                        <Seemore
                            id={3}
                            img="assets/img/home-project3.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Food On Time`}
                            data={FoodOnTime}
                        />
                    </div>
                    <div className="row g-5 pt-5">
                        <Seemore
                            id={4}
                            img="assets/img/yummy_foods.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Yummy foods`}
                            data={YummyFood}
                        />
                        <Seemore
                            id={5}
                            img="assets/img/teleport.jpg"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Teleport`}
                            data={Teleport}
                        />
                        <Seemore
                            id={6}
                            img="assets/img/lugg_port.jpg"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`LuggPort`}
                            data={Luggport}
                        />
                    </div>
                    <div className="row g-5 pt-5">
                        <Seemore
                            id={7}
                            img="assets/img/aty_cloud.jpg"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`ATY Cloud`}
                            data={AtyCloud}
                        />
                        <Seemore
                            id={8}
                            img="assets/img/leadmanage.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Lead management app`}
                            data={LeadManagement}
                        />
                        <Seemore
                            id={9}
                            img="assets/img/home-project3.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Grade Choice`}
                            data={GradeChoice}
                        />
                    </div>
                    <div className="row g-5 pt-5">
                        <Seemore
                            id={10}
                            img="assets/img/avolaAdmin.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Avola Admin
                            `}
                            data={AvolaAdmin}
                        />
                        <Seemore
                            id={11}
                            img="assets/img/onDemandApp.jpg"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`On demand Service app`}
                            data={DemandServiceApp}
                        />
                        <Seemore
                            id={12}
                            img="assets/img/helper_on_time.jpg"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Helper on Time`}
                            data={HelperOnTime}
                        />
                    </div>
                    <div className="row g-5 pt-5">
                        <Seemore
                            id={13}
                            img="assets/img/price_scanner.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Price Scanner`}
                            data={PriceScanner}
                        />
                        <Seemore
                            id={14}
                            img="assets/img/archvtech.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Archvtech`}
                            data={Archvtech}
                        />
                        <Seemore
                            id={15}
                            img="assets/img/Agriculture_TCRS.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Agriculture TCRS`}
                            data={Agriculture}
                        />
                    </div>
                    <div className="row g-5 pt-5">
                        <Seemore
                            id={16}
                            img="assets/img/justoverse.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Justo`}
                            data={Justoverse}
                        />
                        <Seemore
                            id={17}
                            img="assets/img/appyPages.png"
                            name="John Doe"
                            date="01 Jan, 2045"
                            projectName={`Abbypages`}
                            data={abbypages}
                        />
                    </div>
                </div>
            </div>
            {/* Portfolio End */}

            <Footer />
        </div>
    );
}
