import React, { useRef, useState } from "react";
import TopBar from "../Common/Topbar";
import Footer from "../Common/Footer";
import emailjs from "@emailjs/browser";
import Header from "../Common/Header";
import { emailAddressValid, nameValid } from "../../utils/Utils";

export default function ContactUs() {
    const [formState, setFormState] = useState({});
    const [successPop, setSuccessPop] = useState(false);
    const [formVaild, setFormVaild] = useState({});
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        if (!handleValid()) {
            emailjs
                .sendForm(
                    "service_cvqt989",
                    "template_zl1r7di",
                    form.current,
                    "4wD46EHNK3IBsg9QB"
                )
                .then(
                    (result) => {
                        setSuccessPop(true);
                        setFormState({
                            user_name: "",
                            user_email: "",
                            subject: "",
                            message: "",
                        });
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        }
    };

    const handleInputField = (e) => {
        const nextFormState = {
            ...formState,
            [e?.target?.name]: e?.target?.value,
        };
        setFormState(nextFormState);
    };

    const handleValid = () => {
        let err = {};
        let formError = false;
        if (!formState.user_name) {
            formError = true;
            err["user_name"] = "Please enter your full name.";
        } else if (!nameValid(formState.user_name)) {
            formError = true;
            err["user_name"] = "Please enter valid name.";
        }
        if (!formState.user_email) {
            formError = true;
            err["user_email"] = "Please enter your email.";
        } else if (
            formState.user_email &&
            !emailAddressValid(formState.user_email)
        ) {
            formError = true;
            err["user_email"] = "Please enter valid email.";
        }
        if (!formState.subject) {
            formError = true;
            err["subject"] = "Please enter subject.";
        }
        if (!formState.message) {
            formError = true;
            err["message"] = "Please enter message.";
        }
        setFormVaild(err);
        return formError;
    };


    return (
        <div>
            {/* Model_start */}
            {successPop ? (
                <div
                    className="modal show"
                    tabIndex="-1"
                    style={{
                        display: "block",
                    }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="thank-you-pop">
                                    <img
                                        src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png"
                                        alt=""
                                    />
                                    <h1>Thank You!</h1>
                                    <p>
                                        Your submission is received and we will contact you soon.
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    justifyContent: "center",
                                    display: "flex",
                                    padding: "15px",
                                }}
                            >
                                <button
                                    className="btn btn-primary"
                                    style={{ width: "120px" }}
                                    type="button"
                                    onClick={() => {
                                        setSuccessPop(false);
                                    }}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {/* Model_end */}
            <TopBar />
            {/* Navbar_Start */}
            <div className="container-fluid position-relative p-0">
                <Header />
                <div
                    className="container-fluid bg-primary py-5 bg-header-contact"
                    style={{ marginBottom: "90px" }}
                >
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">
                                Contact Us
                            </h1>
                            <h6
                                className="display-4 text-white animated zoomIn"
                                style={{ fontSize: "25px" }}
                            >
                                If you need support or have any questions, please specify what
                                you need <br />
                                help with or provide more information.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            {/* Navbar_End */}
            {/* Contact_Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div
                        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
                        style={{ maxWidth: "600px" }}
                    >
                        <h5 className="fw-bold text-uppercase" style={{ color: "#2ed1d5" }}>
                            Contact Us
                        </h5>
                        <h1 className="mb-0">
                            If You Have Any Query, Feel Free To Contact Us
                        </h1>
                    </div>
                    <div className="row g-5 mb-5">
                        <div className="col-lg-4">
                            <div
                                className="d-flex align-items-center wow fadeIn"
                                data-wow-delay="0.1s"
                            >
                                <div
                                    className="d-flex align-items-center justify-content-center rounded"
                                    style={{
                                        width: "60px",
                                        height: "60px",
                                        backgroundColor: "#2ed1d5",
                                    }}
                                >
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">Call to ask any question</h5>
                                    <h4 className="mb-0" style={{ color: "#2ed1d5" }}>
                                        <a href="tel:+91 9770216812" style={{ color: "#2ed1d5" }}>
                                            {" "}
                                            +91 9770216812{" "}
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div
                                className="d-flex align-items-center wow fadeIn"
                                data-wow-delay="0.4s"
                            >
                                <div
                                    className="d-flex align-items-center justify-content-center rounded"
                                    style={{
                                        width: "60px",
                                        height: "60px",
                                        backgroundColor: "#2ed1d5",
                                    }}
                                >
                                    <i className="fa fa-envelope-open text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">Email to get free query</h5>
                                    <h4 className="mb-0" style={{ color: "#2ed1d5", fontSize: "17px" }}>
                                        <a
                                            style={{ color: "#2ed1d5" }}
                                            href="mailto:salesitinformatix@gmail.com"
                                            className="action-email"
                                        >
                                            salesitinformatix@gmail.com
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div
                                className="d-flex align-items-center wow fadeIn"
                                data-wow-delay="0.8s"
                            >
                                <div
                                    className="d-flex align-items-center justify-content-center rounded"
                                    style={{
                                        width: "60px",
                                        height: "60px",
                                        backgroundColor: "#2ed1d5",
                                    }}
                                >
                                    <i className="fa fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">Visit our office</h5>
                                    <h4 className="mb-0" style={{ color: "#2ed1d5" }}>
                                        Indore (M.P.)
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                            {/* <form
                                ref={form}
                                onSubmit={sendEmail}
                            // ction="mailto:info@itinformatix.com"
                            // method="post"
                            // encType="text/plain"
                            >
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <input
                                            name="user_name"
                                            type="text"
                                            className="form-control border-0 bg-light px-4"
                                            placeholder="Your Name"
                                            style={{ height: "55px" }}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="user_email"
                                            type="email"
                                            className="form-control border-0 bg-light px-4"
                                            placeholder="Your Email"
                                            style={{ height: "55px" }}
                                            required
                                        />
                                    </div>
                                    <div className="col-12">
                                        <input
                                            name="subject"
                                            type="text"
                                            className="form-control border-0 bg-light px-4"
                                            placeholder="Subject"
                                            style={{ height: "55px" }}
                                            required
                                        />
                                    </div>
                                    <div className="col-12">
                                        <textarea
                                            name="message"
                                            className="form-control border-0 bg-light px-4 py-3"
                                            rows="4"
                                            placeholder="Message"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="col-12">
                                        <button
                                            className="btn btn-primary w-100 py-3"
                                            type="submit"
                                            value="Send"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form> */}
                            <form ref={form} onSubmit={sendEmail}>
                                <div class="form-group mb-3">
                                    <label for="exampleInputEmail1">Full Name</label>
                                    <input
                                        type="name"
                                        autoComplete="off"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Full Name"
                                        name="user_name"
                                        onChange={handleInputField}
                                        value={formState.user_name}
                                    />
                                    {formVaild?.user_name && (
                                        <span style={{ color: "red" }}>{formVaild?.user_name}</span>
                                    )}
                                </div>
                                <div class="form-group mb-3">
                                    <label for="exampleInputPassword1">Email</label>
                                    <input
                                        type="email"
                                        autoComplete="off"
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Email"
                                        name="user_email"
                                        onChange={handleInputField}
                                        value={formState.user_email}
                                    />
                                    {formVaild?.user_email && (
                                        <span style={{ color: "red" }}>
                                            {formVaild?.user_email}
                                        </span>
                                    )}
                                </div>
                                <div class="form-group mb-3">
                                    <label for="exampleInputPassword1">Subject</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Subject"
                                        name="subject"
                                        onChange={handleInputField}
                                        value={formState.subject}
                                    />
                                    {formVaild?.subject && (
                                        <span style={{ color: "red" }}>{formVaild?.subject}</span>
                                    )}
                                </div>
                                <div class="form-group mb-5">
                                    <label for="exampleFormControlTextarea1">Message</label>
                                    <textarea
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="5"
                                        placeholder="Message"
                                        onChange={handleInputField}
                                        value={formState.message}
                                        name="message"
                                    ></textarea>
                                    {formVaild?.message && (
                                        <span style={{ color: "red" }}>{formVaild?.message}</span>
                                    )}
                                </div>
                                <div className="col-md-12 text-center">
                                    <button
                                        className="btn btn-primary w-50 py-3"
                                        type="submit"
                                        value="Send"
                                    >
                                        Send Message
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img
                                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                                    data-wow-delay="0.9s"
                                    src="assets/img/map.png"
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>
                        {/* <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                            <iframe
                                className="position-relative rounded w-100 h-100"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.1704540163933!2d75.91310931744384!3d22.721905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e3c1d2c34905%3A0x8e20ef2a5d4856fa!2sItinformatix%20pvt.%20ltd.!5e0!3m2!1sen!2sin!4v1663763356634!5m2!1sen!2sin"
                                frameBorder="0"
                                style={{ minHeight: "350px", border: "0" }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                            ></iframe>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* Contact_End */}

            <Footer />
        </div>
    );
}
