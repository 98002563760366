// email address vaildation
export function emailAddressValid(emailAddress) {
    return emailAddress.match(
        /^([A-Z?a-z0-9_\-\.])+\@([A-Za-z_\-\.])+\.([A-Za-z]{2,4})$/
    );
}

// name validation
export function nameValid(name) {
    // return name.match(/^[a-zA-Z ]{2,30}$/);
    return name.match(/^[A-Za-z0-9\s]{2,30}$/);
}