import React, { useEffect } from "react";
export default function Testimonial() {
    useEffect(() => {
        const owl = window.$(".owl-carousel");
        owl.owlCarousel({
            items: 3,
            margin: 10,
            loop: true,
            nav: true,
            autoplay: true,
            autoplayTimeout: 3000,
        });
    }, []);
    return (
        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div
                    className="section-title text-center position-relative pb-3 mb-4 mx-auto"
                    style={{ maxWidth: "600px" }}
                >
                    <h5 className="fw-bold text-uppercase" style={{ color: "#2ed1d5" }}>
                        Testimonial
                    </h5>
                    <h1 className="mb-0">
                        What Our Clients Say About Our Digital Services
                    </h1>
                </div>
                <div
                    className="owl-carousel testimonial-carousel wow fadeInUp"
                    data-wow-delay="0.6s"
                >
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/Ven_Tadi.webp"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">Ven Tadi</h4>
                                <small className="text-uppercase">Canada</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star-half" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            There was great experience with ITinfomatrix team. They solved my
                            all error in my software as well as in Android application.
                        </div>
                    </div>
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/user.jpg"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">
                                    Iman Nekooeimehr
                                </h4>
                                <small className="text-uppercase">United States</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            Very good experience. Remi was a super star and is an expert in
                            the backend. Very happy with the outcome of the project.
                        </div>
                    </div>
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/testimonial-3.jpg"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">Sergio Gaona</h4>
                                <small className="text-uppercase">Colombia</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star-half" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            OK, he did frontend development with good results. Some
                            difficulties with language because app was in spanish.
                        </div>
                    </div>
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/Saksham_jain.webp"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">Saksham Jain</h4>
                                <small className="text-uppercase">United Kingdom</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            Really great developer. Joined our team very quickly and helped to
                            steady the ship. Develops without needed everything explained.
                        </div>
                    </div>
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/harman.jpg"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">Herman Carneiro</h4>
                                <small className="text-uppercase">United Arab Emirates</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star-half" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            Made React Native components and utilities for me quickly and of
                            good quality. Was very impressed, especially for the cost.
                        </div>
                    </div>
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/sourabh.webp"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">Saurabh Katna</h4>
                                <small className="text-uppercase">India</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            ITinformatix and team was great to work with, well done. Highly
                            recommended, will hire again and thank you!
                        </div>
                    </div>
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/Parker-Place.webp"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">Parker Place</h4>
                                <small className="text-uppercase">United States</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star-half" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            Talented developer. They proved their expertized in Node JS as
                            well Android app development. Thanks all team members.
                        </div>
                    </div>
                    <div className="testimonial-item bg-light my-4">
                        <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                            <img
                                className="img-fluid rounded"
                                src="assets/img/marius.jpg"
                                style={{ width: "60px", height: "60px" }}
                            />
                            <div className="ps-4">
                                <h4 className="text-primary mb-1 font_size">
                                    Marius Rebenciuc
                                </h4>
                                <small className="text-uppercase">Romania</small>
                                <div style={{ color: "#06a3da" }}>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4 pb-5 px-5">
                            Thanks for helping us on our deliverables. Great work. Very happy with the outcome of the project. Well Done!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
