import React from "react";

export default function Service() {
    return (
        <div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div
                        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
                        style={{ maxWidth: "600px" }}
                    >
                        <h5 className="fw-bold text-uppercase" style={{ color: "#2ed1d5" }}>
                            Our Services
                        </h5>
                        <h1 className="mb-0">
                            Custom IT Solutions for Your Successful Business
                        </h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-mobile-alt text-white"></i>
                                </div>
                                <h4 className="mb-3">Mobile Development</h4>
                                <p className="m-0">
                                    Mobile application development is the process of making
                                    software for smartphones, tablets and digital assistants, most
                                    commonly for the Android and iOS operating systems.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-globe text-white"></i>
                                </div>
                                <h4 className="mb-3">Web Development</h4>
                                <p className="m-0">
                                    Web development is the work involved in developing a website
                                    for the Internet (World Wide Web) or an intranet (a private
                                    network).
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-chart-pie text-white"></i>
                                </div>
                                <h4 className="mb-3">Digital Marketing</h4>
                                <p className="m-0">
                                    Digital marketing, also called online marketing, is the
                                    promotion of brands to connect with potential customers using
                                    the internet and other forms of digital communication.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-code text-white"></i>
                                </div>
                                <h4 className="mb-3">Quality Assurance</h4>
                                <p className="m-0">
                                    The maintenance of a desired level of quality in a service or
                                    product, especially by means of attention to every stage of
                                    the process of delivery or production.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-search text-white"></i>
                                </div>
                                <h4 className="mb-3">SEO Optimization</h4>
                                <p className="m-0">
                                    SEO - Search engine optimization: the process of making your
                                    site better for search engines. Also the job title of a person
                                    who does this for a living.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div
                                className="position-relative rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5"
                                style={{ backgroundColor: "#2ed1d5" }}
                            >
                                <h3 className="text-white mb-3">Call Us For Query</h3>
                                <p className="text-white mb-3">
                                    Our IT Solutions will get you on move towards your destination
                                    faster than rivals in a more reliably way!
                                </p>
                                <h2 className="text-white mb-0" style={{ fontSize: "27px" }}>
                                    {" "}
                                    <a href="tel:+91 9770216812" style={{ color: "white" }}>
                                        {" "}
                                        +91 9770216812{" "}
                                    </a>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
