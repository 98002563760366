import React from "react";

export default function About() {
    return (
        <div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h5
                                    className="fw-bold text-uppercase"
                                    style={{ color: "#2ed1d5" }}
                                >
                                    About Us
                                </h5>
                                <h1 className="mb-0">
                                    The Best IT Solution With 5 Years of Experience
                                </h1>
                            </div>
                            <p className="mb-4">
                                Let us be your next Preferred IT Partner With more than 5 years
                                of experience, Itinformatix provides the solutions any company
                                needs to continuously expand while building a strong online
                                presence. Itinformatix developers produce outstanding designs,
                                clever business plans, and cutting-edge digital solutions for
                                our customers.
                            </p>
                            <div className="row g-0 mb-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-3">
                                        <i
                                            className="fa fa-check me-3"
                                            style={{ color: "#2ed1d5" }}
                                        ></i>
                                        Fair Prices
                                    </h5>
                                    <h5 className="mb-3">
                                        <i
                                            className="fa fa-check me-3"
                                            style={{ color: "#2ed1d5" }}
                                        ></i>
                                        Professional Staff
                                    </h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-3">
                                        <i
                                            className="fa fa-check me-3"
                                            style={{ color: "#2ed1d5" }}
                                        ></i>
                                        24/7 Support
                                    </h5>
                                </div>
                            </div>
                            <div
                                className="d-flex align-items-center mb-4 wow fadeIn"
                                data-wow-delay="0.6s"
                            >
                                <div
                                    className="d-flex align-items-center justify-content-center rounded"
                                    style={{
                                        width: "60px",
                                        height: "60px",
                                        backgroundColor: "#2ed1d5",
                                    }}
                                >
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">Call to ask any question</h5>
                                    <h4 className="mb-0" style={{ color: "#2ed1d5" }}>
                                        <a
                                            href="tel:+91 9770216812"
                                            style={{ color: "#2ed1d5" }}
                                        >
                                            {" "}
                                            +91 9770216812{" "}
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img
                                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                                    data-wow-delay="0.9s"
                                    src="assets/img/about.jpg"
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
