import React, { useState } from "react";

function Seemore(props) {
    const [seelessmore, setseeLessMore] = useState({ id: props.id, value: true });
    return (
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
            <div className="blog-item bg-light rounded overflow-hidden">
                <div className="blog-img position-relative overflow-hidden">
                    <img src={props.img} alt="" className="img-fluid" />
                </div>
                <div className="p-4">
                    <div className="d-flex mb-3">
                        <small className="me-3">
                            <i className="far fa-user text-primary me-2"></i>
                            {props.name}
                        </small>
                        <small>
                            <i className="far fa-calendar-alt text-primary me-2"></i>
                            {props.date}
                        </small>
                    </div>
                    <h4 className="mb-3">{props.projectName}</h4>
                    <p>
                        {seelessmore.id === props.id && !seelessmore.value
                            ? props.data
                            : `${props.data.substring(0, 100)}`}
                    </p>
                    <a
                        className="text-uppercase"
                        style={{ color: "#2ed1d5", cursor: "pointer" }}
                        onClick={() =>
                            setseeLessMore({ id: props.id, value: !seelessmore.value })
                        }
                    >
                        {seelessmore.value ? "Read More" : "Read Less"}{" "}
                        <i className="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Seemore;
