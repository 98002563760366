import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./Screens/Home/Home";
import AboutUs from "./Screens/AboutUs/AboutUs";
import Services from "./Screens/Services/Services";
import IndustrialTraining from "./Screens/IndustrialTraining/Industrial";
import ContactUs from "./Screens/Contact/ContactUs";
import Portfolio from "./Screens/Portfolio/Portfolio";
import PrivacyPolicy from "./Screens/PrivacyPolicy/PrivacyPolicy";
function Routes() {
  return (
    <div>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/aboutUs">
              <AboutUs />
            </Route>
            <Route exact path="/portfolio">
              <Portfolio />
            </Route>
            <Route exact path="/services">
              <Services />
            </Route>
            <Route exact path="/IndustrialTraining">
              <IndustrialTraining />
            </Route>
            <Route exact path="/contactUs">
              <ContactUs />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}
export default Routes;
