import React from "react";
import TopBar from "../Common/Topbar";
import About from "../Common/About";
import Service from "../Common/Service";
import Testimonial from "../Common/Testimonial";
import TeamMember from "../Common/TeamMember";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";
import Header from "../Common/Header";

export default function Home() {
    return (
        <div>
            <TopBar />
            {/* Navbar_Start */}
            <div className="container-fluid position-relative p-0">
                <Header />
                <div
                    id="header-carousel"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <video className="w-100 videoTag" autoPlay loop muted>
                                <source src="assets/img/backVideo-1.mp4" type="video/mp4" />
                            </video>
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: "900px" }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                                        Creative & Innovative
                                    </h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                                        Creative & Innovative Digital Solution
                                    </h1>
                                    <Link
                                        to="/contactUs"
                                        className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                                    >
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <video className="w-100 videoTag" autoPlay loop muted>
                                <source src="assets/img/backVideo-2.mp4" type="video/mp4" />
                            </video>
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: "1000px" }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                                        INTELLIGENCE & EXPERIENCE
                                    </h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                                        Intelligence & Experience IT Solution
                                    </h1>
                                    <Link
                                        to="/contactUs"
                                        className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                                    >
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <video className="w-100 videoTag" autoPlay loop muted>
                                <source src="assets/img/backVideo-3.mp4" type="video/mp4" />
                            </video>
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: "900px" }}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                                        Creative & Innovative
                                    </h5>
                                    <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                                        Launch Outstanding Products & Experience
                                    </h1>
                                    <Link
                                        to="/contactUs"
                                        className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                                    >
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#header-carousel"
                        data-bs-slide="prev"
                    >
                        <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#header-carousel"
                        data-bs-slide="next"
                    >
                        <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* Navbar_End */}

            {/* Fact_Start */}
            <div className="container-fluid facts py-5 pt-lg-0">
                <div className="container py-5 pt-lg-0">
                    <div className="row gx-0">
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                            <div
                                className="shadow d-flex align-items-center justify-content-center p-4"
                                style={{ height: "150px", backgroundColor: "#2ed1d5" }}
                            >
                                <div
                                    className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                                    style={{ width: "60px", height: "60px" }}
                                >
                                    <i className="fa fa-award" style={{ color: "#2ed1d5" }}></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="text-white mb-0">Year of Experience</h5>
                                    <h1
                                        className="counter text-white mb-0"
                                        data-toggle="counter-up"
                                    >
                                        5
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                            <div
                                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                                style={{ height: "150px" }}
                            >
                                <div
                                    className="d-flex align-items-center justify-content-center rounded mb-2"
                                    style={{
                                        width: "60px",
                                        height: "60px",
                                        backgroundColor: "#2ed1d5",
                                    }}
                                >
                                    <i className="fa fa-users text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-0" style={{ color: "#2ed1d5" }}>
                                        Happy Clients
                                    </h5>
                                    <h1 className="mb-0" data-toggle="counter-up">
                                        120+
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="shadow d-flex align-items-center justify-content-center p-4"
                                style={{ height: "150px", backgroundColor: "#2ed1d5" }}
                            >
                                <div
                                    className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                                    style={{ width: "60px", height: "60px" }}
                                >
                                    <i className="fa fa-check" style={{ color: "#2ed1d5" }}></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="text-white mb-0">Projects Done</h5>
                                    <h1 className="text-white mb-0" data-toggle="counter-up">
                                        50+
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fact_End */}

            <About />

            {/*  Features Start  */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div
                        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
                        style={{ maxWidth: "700px" }}
                    >
                        <h5 className="fw-bold text-uppercase" style={{ color: "#2ed1d5" }}>
                            Technologies That We Serve
                        </h5>
                        <h1 className="mb-0">
                            A Trusted Partner For Web App & Mobile App Development
                        </h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4">
                            <div className="row g-5">
                                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                                    <div
                                        className="rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: "60px", height: "60px" }}
                                    >
                                        <img
                                            srcSet=""
                                            src="assets/img/technology-react-color.svg"
                                            alt=""
                                            style={{ height: "40px" }}
                                        />
                                    </div>
                                    <h4>React.JS</h4>
                                    <p className="mb-0">
                                        It’s the favourite JavaScript framework of our frontend
                                        developers, letting them craft beautiful & intuitive
                                        interfaces.
                                    </p>
                                </div>
                                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                                    <div
                                        className="rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: "60px", height: "60px" }}
                                    >
                                        <img
                                            srcSet=""
                                            src="assets/img/technology-react-color.svg"
                                            alt=""
                                            style={{ height: "40px" }}
                                        />
                                    </div>
                                    <h4>React Native</h4>
                                    <p className="mb-0">
                                        What is React Native? React Native is a framework for
                                        building native iOS and Android applications using
                                        JavaScript.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row g-5">
                                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                                    <div
                                        className="rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: "60px", height: "60px" }}
                                    >
                                        <img
                                            srcSet=""
                                            src="assets/img/technology-nodejs-2-color.svg"
                                            alt=""
                                            style={{ height: "40px" }}
                                        />
                                    </div>
                                    <h4>Node.JS</h4>
                                    <p className="mb-0">
                                        We also excel in Node.js development, which is an ideal
                                        solution when building backend for fintech applications.
                                    </p>
                                </div>
                                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                                    <div
                                        className="rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: "60px", height: "60px" }}
                                    >
                                        <img
                                            srcSet=""
                                            src="assets/img/odoo.png"
                                            alt=""
                                            style={{ height: "20px" }}
                                        />
                                    </div>
                                    <h4>Odoo</h4>
                                    <p className="mb-0">
                                        ODOO has become the favourite choice for many businesses
                                        because it handles a range of business needs.
                                    </p>
                                </div>
                                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                                    <div
                                        className="rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: "60px", height: "60px" }}
                                    >
                                        <img
                                            srcSet=""
                                            src="assets/img/postgresql-icon.svg"
                                            alt=""
                                            style={{ height: "40px" }}
                                        />
                                    </div>
                                    <h4>PostgreSQL</h4>
                                    <p className="mb-0">
                                        PostgreSQL is a powerful, open source object-relational
                                        database system with over 35 years of active development
                                        that has earned it a strong reputation for reliability.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row g-5">
                                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                                    <div
                                        className="rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: "60px", height: "60px" }}
                                    >
                                        <img
                                            srcSet=""
                                            src="assets/img/technology-mongodb-color.svg"
                                            alt=""
                                        />
                                    </div>
                                    <h4>Mongodb</h4>
                                    <p className="mb-0">
                                        MongoDB, the most popular NoSQL database, is an open-source
                                        document-oriented database.
                                    </p>
                                </div>
                                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                                    <div
                                        className="rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: "60px", height: "60px" }}
                                    >
                                        <img
                                            srcSet=""
                                            src="assets/img/technology-mysql-color.svg"
                                            alt=""
                                        />
                                    </div>
                                    <h4>MySQL</h4>
                                    <p className="mb-0">
                                        A database is a separate application that stores a
                                        collection of data. Each database has one or more distinct
                                        APIs for creating.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  Features End  */}

            <Service />
            <Testimonial />
            {/* <TeamMember /> */}

            {/* Portfolio_Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div
                        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
                        style={{ maxWidth: "600px" }}
                    >
                        <h5 className="fw-bold text-uppercase" style={{ color: "#2ed1d5" }}>
                            Portfolio
                        </h5>
                        <h1 className="mb-0">Have A Look At Some Of Our Success Stories</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                            <div className="blog-item bg-light rounded overflow-hidden">
                                <div className="blog-img position-relative overflow-hidden">
                                    <img
                                        className="img-fluid"
                                        src="assets/img/home-project4.png"
                                        alt=""
                                    />
                                </div>
                                <div className="p-4">
                                    <div className="d-flex mb-3">
                                        <small className="me-3">
                                            <i className="far fa-user text-primary me-2"></i>John Doe
                                        </small>
                                        <small>
                                            <i className="far fa-calendar-alt text-primary me-2"></i>
                                            01 Jan, 2045
                                        </small>
                                    </div>
                                    <h4 className="mb-3">Form Duniya</h4>
                                    <p>
                                        India's No 1 form filling platform for aspirant of
                                        government exam. Student Exam forms can be submitted on
                                        simple click using easy user interface.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                            <div className="blog-item bg-light rounded overflow-hidden">
                                <div className="blog-img position-relative overflow-hidden">
                                    <img
                                        className="img-fluid"
                                        src="assets/img/home-project2.png"
                                        alt=""
                                    />
                                </div>
                                <div className="p-4">
                                    <div className="d-flex mb-3">
                                        <small className="me-3">
                                            <i className="far fa-user text-primary me-2"></i>John Doe
                                        </small>
                                        <small>
                                            <i className="far fa-calendar-alt text-primary me-2"></i>
                                            01 Jan, 2045
                                        </small>
                                    </div>
                                    <h4 className="mb-3">On Demand App</h4>
                                    <p>
                                        It’s one of the largest home service app. Through the app,
                                        you can order reliable home services - everything be it
                                        electrician, plumber, cleaner, trainer...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                            <div className="blog-item bg-light rounded overflow-hidden">
                                <div className="blog-img position-relative overflow-hidden">
                                    <img
                                        className="img-fluid"
                                        src="assets/img/home-project3.png"
                                        alt=""
                                    />
                                </div>
                                <div className="p-4">
                                    <div className="d-flex mb-3">
                                        <small className="me-3">
                                            <i className="far fa-user text-primary me-2"></i>John Doe
                                        </small>
                                        <small>
                                            <i className="far fa-calendar-alt text-primary me-2"></i>
                                            01 Jan, 2045
                                        </small>
                                    </div>
                                    <h4 className="mb-3">Food On Time</h4>
                                    <p>
                                        Food on time lets you discover restaurants to eat out at or
                                        order in from. Browse through restaurant menus, photos, user
                                        reviews and...
                                    </p>
                                    <Link
                                        className="text-uppercase"
                                        to="/portfolio"
                                        style={{ float: "right", color: "#2ed1d5" }}
                                    >
                                        Read More <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Portfolio_End */}

            <Footer />
        </div>
    );
}
