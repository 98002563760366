import React from "react";
import TopBar from "../Common/Topbar";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";
import Header from "../Common/Header";

export default function IndustrialTraining() {
    const myFunction = () => {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "Read more";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
        }
    };
    const myFunctionOne = () => {
        var dotsOne = document.getElementById("dotsOne");
        var moreTextOne = document.getElementById("moreOne");
        var btnTextOne = document.getElementById("myBtnOne");

        if (dotsOne.style.display === "none") {
            dotsOne.style.display = "inline";
            btnTextOne.innerHTML = "Read more";
            moreTextOne.style.display = "none";
        } else {
            dotsOne.style.display = "none";
            btnTextOne.innerHTML = "Read less";
            moreTextOne.style.display = "inline";
        }
    };
    const myFunctionTwo = () => {
        var dotsTwo = document.getElementById("dotsTwo");
        var moreTextTwo = document.getElementById("moreTwo");
        var btnTextTwo = document.getElementById("myBtnTwo");

        if (dotsTwo.style.display === "none") {
            dotsTwo.style.display = "inline";
            btnTextTwo.innerHTML = "Read more";
            moreTextTwo.style.display = "none";
        } else {
            dotsTwo.style.display = "none";
            btnTextTwo.innerHTML = "Read less";
            moreTextTwo.style.display = "inline";
        }
    };

    return (
        <div>
            <TopBar />
            {/* Navbar_Start */}
            <div className="container-fluid position-relative p-0">
                <Header />
                <div
                    className="container-fluid bg-primary py-5 bg-header-industrialTraining"
                    style={{ marginBottom: "90px" }}
                >
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">
                                Industrial Training
                            </h1>
                            <h6
                                className="display-4 text-white animated zoomIn"
                                style={{ fontSize: "25px" }}
                            >
                                A successful career is just one step away !!!
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            {/* Navbar_End */}
            {/* Training_Start */}
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div
                        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
                        style={{ maxWidth: "600px" }}
                    >
                        <h5 className="fw-bold text-uppercase" style={{ color: "#2ed1d5" }}>
                            Our Digital Marketing Courses
                        </h5>
                        <h1 className="mb-0">
                            Custom IT Solutions for Your Successful Business
                        </h1>
                    </div>
                    <div className="row g-0">
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                            <div className="bg-light rounded">
                                <div className="border-bottom py-4 px-5 mb-4">
                                    <h4 className="mb-1" style={{ color: "#2ed1d5" }}>
                                        Digital Marketing Certification
                                    </h4>
                                    <small className="text-uppercase">1 Month</small>
                                </div>
                                <div className="p-5 pt-0">
                                    <h1 className="display-5 mb-3"></h1>
                                    <p className="mb-3">
                                        Digital Marketing Fundamentals
                                        <br />
                                        Website Planning and Structure
                                        <br />
                                        SEO - On Page, Off Page
                                        <br />
                                        PPC (Google Adwords)<span id="dots">...</span>
                                        <span id="more">
                                            <br />
                                            Social Media - Facebook, Twitter, Linkedin etc.
                                            <br />
                                            Email Marketing - Tools, Templates, Funnel, Tracking
                                            <br />
                                            Web Analytics - Google analytics
                                            <br />
                                            Blogging
                                        </span>
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => myFunction()}
                                        id="myBtn"
                                    >
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                            <div
                                className="bg-white rounded shadow position-relative"
                                style={{ zIndex: "1" }}
                            >
                                <div className="border-bottom py-4 px-5 mb-4">
                                    <h4
                                        className="mb-1"
                                        style={{ color: "#2ed1d5" }}
                                    >
                                        Advanced Digital Marketing Certification
                                    </h4>
                                    <small className="text-uppercase">2 Months</small>
                                </div>
                                <div className="p-5 pt-0">
                                    <p className="mb-3">
                                        Digital Marketing Fundamentals
                                        <br />
                                        Website Planning and Structure
                                        <br />
                                        Wordpress Setup and Management
                                        <br />
                                        Advanced SEO - On Page, Off Page
                                        <span id="dotsOne">...</span>
                                        <span id="moreOne">
                                            <br />
                                            Blogging, Content Writing, Content Marketing
                                            <br />
                                            Reputation Marketing - Citation, Feedback, Reviews,
                                            Ratings, Social media
                                            <br />
                                            Web Analytics - Google analytics, Heatmap, Insight
                                            Analytics
                                            <br />
                                            Pay Per Click (Google, Bing)
                                            <br />
                                            Social Media - Facebook, Twitter, Linkedin, GPlus,
                                            Instagram, etc.
                                            <br />
                                            Email Marketing - Tools, Templates, Funnel, Tracking
                                            <br />
                                            Video Marketing (Youtube, Vimeo)
                                            <br />
                                            Affiliate Marketing
                                            <br />
                                            Ad Sense
                                            <br />
                                            E-commerce Marketing (Analytics, On page, Feed Setup)
                                            <br />
                                            Lead Generation (Tools, Strategy, Scripts)
                                            <br />
                                            Mobile Marketing
                                            <br />
                                            App-Store Optimization
                                            <br />
                                            Freelancing Portal Training
                                            <br />
                                        </span>
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => myFunctionOne()}
                                        id="myBtnOne"
                                    >
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                            <div className="bg-light rounded">
                                <div className="border-bottom py-4 px-5 mb-4">
                                    <h4
                                        className="mb-1"
                                        style={{ color: "#2ed1d5" }}
                                    >
                                        Advanced Digital Marketing Diploma
                                    </h4>
                                    <small className="text-uppercase">3 Months</small>
                                </div>
                                <div className="p-5 pt-0">
                                    <p className="mb-3">
                                        Digital Marketing Fundamentals
                                        <br />
                                        Website Planning and Structure
                                        <br />
                                        HTML, CSS Basics
                                        <br />
                                        Keyword Planning<span id="dotsTwo">...</span>
                                        <span id="moreTwo">
                                            <br />
                                            Advanced SEO - On Page, Off Page
                                            <br />
                                            Pay Per Click (Google, Bing)
                                            <br />
                                            Remarketing Strategies
                                            <br />
                                            Landing Pages
                                            <br />
                                            Competitor Research
                                            <br />
                                            Social Media - Facebook, Twitter, Linkedin, GPlus,
                                            Instagram, etc.
                                            <br />
                                            Email Marketing - Tools, Templates, Funnel, Tracking
                                            <br />
                                            Web Analytics - Google analytics, Heatmap, Insight
                                            Analytics
                                            <br />
                                            Content Writing - Copy Writing Training
                                            <br />
                                            Video Marketing (Youtube, Vimeo)
                                            <br />
                                            Affiliate Marketing
                                            <br />
                                            Reputation Marketing - Citations, Reviews, Ratings, Social
                                            media
                                            <br />
                                            E-commerce Marketing (Analytics, On Page, Feed Setup)
                                            <br />
                                            WordPress Setup and Management
                                            <br />
                                            Lead Generation (Tools, Strategy, Scripts)
                                            <br />
                                            Blogging, Content Marketing
                                            <br />
                                            Ad Sense
                                            <br />
                                            Mobile Marketing
                                            <br />
                                            App-Store Optimization
                                            <br />
                                            Freelancing Portal Training
                                            <br />
                                            Marketing Tools- Automation
                                            <br />
                                            Viral Marketing
                                            <br />
                                            Project Management (CRM)
                                            <br />
                                            Web Audit
                                            <br />
                                            Reporting
                                            <br />
                                            Interview Preparation
                                            <br />
                                        </span>
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => myFunctionTwo()}
                                        id="myBtnTwo"
                                    >
                                        Read More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 pt-5" style={{ justifyContent: 'center' }}>
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                            <div className="bg-light rounded">
                                <div className="border-bottom py-4 px-5 mb-4">
                                    <h4 className="mb-1" style={{ color: "#2ed1d5" }}>
                                        Internship For <br />
                                        Software Developer
                                    </h4>
                                    <small className="text-uppercase">45 Days</small>
                                </div>
                                <div className="p-5 pt-0">
                                    <h1 className="display-5 mb-3"></h1>
                                    <p className="mb-3">
                                        React.JS
                                        <br />
                                        Node.JS
                                        <br />
                                        React Native
                                        <br />
                                        Mongodb
                                        <br />
                                        MySQL
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Training_End */}

            <Footer />
        </div>
    );
}
