import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                <div className="flex-item">
                    <div className="logo">
                        <a href="/">
                            <img
                                src="assets/img/itinformatix.png"
                                alt=""
                                className="img-fluid"
                            />
                        </a>
                    </div>
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                >
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <Link className="nav-link" to="/">
                            Home
                        </Link>
                        <Link className="nav-link" to="/aboutUs">
                            About Us
                        </Link>
                        <Link className="nav-link" to="/portfolio">
                            Portfolio
                        </Link>
                        <Link className="nav-link" to="/services">
                            Services
                        </Link>
                        <Link className="nav-link" to="/IndustrialTraining">
                            Industrial Training
                        </Link>
                        <Link className="nav-link" to="/contactUs">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </nav>
        </div>
    );
}
